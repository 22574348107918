#infori-plot {
  height: 700px;
}

#period-ratio-plot {
  height: 240px;
}

.cluster-tooltip {
  max-width: 500px;
}

.keyword-link {
  margin-top: 0.4em;
  margin-bottom: 0.4em;

  a:link {
    text-decoration: none !important;
  }
}

.scrolling-wrapper {
  width: 800px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .column {
    display: inline-block;
    vertical-align: top;
  }
}

.ai-message {
  white-space: pre-line;
}

.keyword-input {
  width: 40vw;
}